<template>
  <div class="createPaymentClosingHistory">
    <TheSubHeader 
      ref="theSubHeader"  
      icon="money"
      title="Financeiro"
      subtitle="Fechamento de Pagamentos"
      page-title-tag="Financial Create Payment Closing History"
      type="routerTabs"
      organizer-gap="1rem"
    >
      <template #afterButtons>
        <BaseButton
          v-if="permissions && permissions.editVehicles && permissions.editVehicles.isVisible"
          icon="truck-delivered"
          color="primary-base"
          label="Tipo de Veículo"
          class="car-type-button"
          :track="trackSubheaderButtonString('Financial Create Payment Closing History', 'Financial Edit Vehicle')"
          @click="goToEditVehicle()"
        />
        <BaseButton
          icon="status"
          color="primary-base"
          label="Custo de operação"
          class="operation-button"
          :track="trackSubheaderButtonString('Financial Create Payment Closing History', 'Operation Cost')"
          @click="openModalOperation()"
        />
      </template>
      <template #filter>
        <section
          class="Financial__subheaderTab Financial__subheaderTab--import"
        >
          <BaseDatePicker
            v-model="paymentClosingDate"
            label="Data de pagamento"
            :not-before="paymentClosingMinDate"
            placeholder=""
            disable-weekends
            :range="false"
            :prepend-icon="false"
          />

          <InputFile 
            ref="inputFile"
            v-model="paymentClosingFile"
            label="Planilha"
            btn-label="Escolha o arquivo"
            accept-files=".xlsx, .xls"
          />

          <BaseButton
            label="Importar arquivo"
            theme="icon-left"
            filled
            color="primary-base"
            text-color="white"
            icon="download"
            :disabled="disableSubmitPaymentClosing"
            @click="submitPaymentClosingFile"
          />
        </section>
      </template>
    </TheSubHeader>
  </div>  
</template>

<script>
import { mapActions, mapState } from 'vuex'
//Components
import { TheSubHeader } from '@/components/organisms'
import { BaseButton, BaseDatePicker } from '@/components/atoms'
import { InputFile } from '@/components/molecules'
//Mixins
import FinancialMixin from '@/mixins/financial'
//Api
import { financial } from '@/api'

export default {
  name: 'CreatePaymentClosingHistory',
  components: { 
    TheSubHeader,
    BaseButton,
    BaseDatePicker,
    InputFile,
  },
  mixins: [FinancialMixin],
  data() {
    return {
      paymentClosingMinDate: this.getPaymentClosingMinDate(),
      paymentClosingDate: null,
      paymentClosingFile: null,
    }
  },
  computed: {
    ...mapState({
      permissions: state => state.auth.permissions
    }),
    
    disableSubmitPaymentClosing() {
      return this.paymentClosingFile === null || this.paymentClosingDate === null || this.paymentClosingDate === ''
    },
  },
  mounted() {
    this.clearPagination()
  },
  methods: {
    ...mapActions({
      setLoading: 'loading/setLoading',
      clearPagination: 'pagination/clearPagination'
    }),

    getPaymentClosingMinDate() {
      let date = new Date()
      if (date.getHours() >= 16) {
        date = new Date()
        date.setDate(date.getDate() + 1)
      }
      return Date.parse(date)
    },

    async submitPaymentClosingFile() {
      if (this.paymentClosingFile != null) {
        let formData = new FormData()
        formData.append('date', this.paymentClosingDate)
        formData.append('file', this.paymentClosingFile)
        
        this.setLoading(true)
        await financial.submitPaymentClosingFile(formData, () => {
          this.$router.push('payment-closing-history')
        }, (e) => e)
        this.setLoading(false)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.createPaymentClosingHistory {
  .Financial {
    &__subheaderTab {
      display: grid;
      align-items: end;
      grid-gap: 1rem;
        @media #{$mobile-view} {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
      
      &--import {
        grid-template-columns: 150px 1fr 200px;
      }
    }

    &__modalHeader {
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 0.5rem;
    }
  }
}
</style>
